import * as Sentry from "@sentry/browser"

const { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_RELEASE, USER_ID, USERNAME } =
    JSON.parse(document.querySelector("#SENTRY").textContent)

const unhandledExceptionMessages = [
    "Object captured as exception",
    "Non-Error exception captured with keys",
]

const unhandledExceptionRegex = new RegExp(
    "^(" + unhandledExceptionMessages.join("|") + ")"
)

function isErrFromDatatables(event) {
    try {
        return event.extra.__serialized__.namespace === "dt"
    } catch {
        return false
    }
}

function isNonErrorException(event, hint) {
    let evtMsg
    let hintMsg
    try {
        hintMsg = hint.originalException["message"]
    } catch {}
    try {
        evtMsg = event.exception.values[0].value
    } catch {}

    return (
        (evtMsg !== undefined && unhandledExceptionRegex.test(evtMsg)) ||
        (hintMsg !== undefined && unhandledExceptionRegex.test(hintMsg))
    )
}

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: SENTRY_RELEASE,
        replaysOnErrorSampleRate: 0.1,
        initialScope: { user: { id: USER_ID, username: USERNAME } },
        beforeSend(event, hint) {
            if (
                isNonErrorException(event, hint) &&
                isErrFromDatatables(event)
            ) {
                return null
            }
            return event
        },
        integrations: [
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
    })
}

function setDatatableErrMode() {
    if (SENTRY_DSN) {
        $.fn.dataTable.ext.errMode = function (settings, techNote, msg) {
            const err = new Error(msg)
            Sentry.captureException(err)
        }
    }
}

export { setDatatableErrMode }
